import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layouts/page";
import SpotlightTalk from "../components/spotlight-talk";
import data from "../../data/talks.json";

const { talks } = data;

export default function Home() {
  return (
    <Layout path="/">
      <Helmet>
        <meta
          name="Danielle Adams - Software Engineer"
          content="Danielle Adams is a software engineer based in Austin."
        />
      </Helmet>

      <div className="row">
        <div className="col-12 col-md-6 p-5">
          <img
            src="/home.png"
            className="img-fluid header-img"
            alt="im danielle"
          />
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 col-md-8 px-5">
          <h5 className="light">
            I'm a software engineer and privacy advocate based in Austin, TX.
          </h5>
        </div>
      </div>
      <div className="row p-5">
        <div className="col-12 col-md-4 pb-20">
          <SpotlightTalk
            type="talk"
            date={talks[0].date}
            title={talks[0].title}
            description={talks[0].abstract}
            video="true"
            youtubeId={talks[0].youtubeId}
          />
        </div>
        <div className="col-12 col-md-4 pl-15 pb-20">
          <SpotlightTalk
            type="podcast"
            date={talks[1].date}
            title="OpenHive.JS Podcast, Episode 14: Danielle Adams on Cloud Native Buildpacks"
            description={talks[1].abstract}
            youtubeId={talks[1].youtubeId}
          />
        </div>
        <div className="col-12 col-md-4 pl-15 pb-20">
          <SpotlightTalk
            type="podcast"
            date={talks[2].date}
            title="Code Newbie Podcast, Season 15, Ep 14: What is Node.js and when might you use it with Danielle Adams"
            description={talks[2].abstract}
            clickUrl={talks[2].url}
          />
        </div>
      </div>
    </Layout>
  );
}
