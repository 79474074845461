import React from "react";
import youtubeEmbedUrl from "../../helpers/youtube-embed-url";

export default function Youtube({ youtubeId, height, classNames }) {
  return (
    <iframe
      src={youtubeEmbedUrl(youtubeId)}
      className={classNames}
      height={height}
      title="video"
    ></iframe>
  );
}
