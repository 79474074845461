import React from "react";
import Truncate from "./helpers/truncate";
import Youtube from "./helpers/youtube";
import youtubeUrl from "../helpers/youtube-url";

export default function Spotlight({
  type,
  date,
  title,
  description,
  youtubeId,
  video,
  clickUrl,
}) {
  return (
    <div className="spotlight-card container-fluid">
      <div className="row mx-1 mt-2">
        <div className="col-12 d-flex justify-content-start">
          <p>
            <span className="muted">
              <span className="bold">{type}</span> • {date}
            </span>
          </p>
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-12 d-flex justify-content-center min-ht-200">
          {youtubeId ? (
            <Youtube
              youtubeId={youtubeId}
              classNames="card-img-top"
              height="200"
            />
          ) : (
            <a href={clickUrl} target="_blank" rel="noreferrer">
              <img
                src="/podcast.png"
                className="img-fluid"
                alt="Podcast episode"
              />
            </a>
          )}
        </div>
      </div>
      <div className="row mx-1 my-3">
        <div className="col-12 d-flex justify-content-start">
          <h5>
            <a
              href={youtubeId ? youtubeUrl(youtubeId) : clickUrl}
              target="_blank"
              rel="noreferrer"
            >
              {title}
            </a>
          </h5>
        </div>
      </div>
      <div className="row m-1">
        <div className="col-12 d-flex justify-content-start">
          <Truncate string={description} length="290" />
        </div>
      </div>
      <div className="row m-1">
        <div className="col-12 d-flex justify-content-end">
          <p>
            <a
              href={youtubeId ? youtubeUrl(youtubeId) : clickUrl}
              className="upcase cta"
              target="_blank"
              rel="noreferrer"
            >
              {video ? "Watch" : "Listen"} Now
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
